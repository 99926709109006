import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import Button from './Button'
import Icon from './Icon'

export default function FeatureItem({ feature }) {
  const flexDirection = feature.flipped ? 'lg:flex-row-reverse' : 'lg:flex-row'

  return (
    <div className="bg-white w-full my-24">
      <div
        className={`bg-white max-w-7xl mx-auto px-6 gap-4 space-y-12 flex flex-col-reverse ${flexDirection}`}
      >
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
          <div className="mt-6">
            <div className="flex flex-row items-center align-middle gap-2 md:gap-4">
              <span className="hidden md:flex h-12 w-12 items-center justify-center rounded bg-uforange-600">
                <Icon
                  name={feature.iconName}
                  className={'w-6 h-6 text-white'}
                />
              </span>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {feature.title}
              </h2>
            </div>
            <div className="mt-4 text-lg text-gray-500">
              {feature.description?.raw && renderRichText(feature.description)}
            </div>
            {feature.actionLabel && feature.actionLink && (
              <div className="mt-6 flex flex-col md:flex-row space-x-2">
                <Button label={feature.actionLabel} href={feature.actionLink} />
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-star">
          {feature.showGif ? (
            <img src="images/common/ue-one.gif" alt="UE one animation" />
          ) : (
            <GatsbyImage
              alt={feature.imgAlt || feature.title || 'Feature image'}
              image={feature?.image?.gatsbyImage}
              className="w-full rounded lg:h-full lg:w-220"
            />
          )}
        </div>
      </div>
    </div>
  )
}
