import React from 'react'
import links from '../../config/links'

export default function AppCTA({ topPadded = false }) {
  return (
    <div className={`bg-white ${topPadded ? 'pt-32' : null}`}>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-24 lg:px-8 flex flex-row">
        <div className="flex flex-col max-w-4xl items-start justify-start">
          <h2
            className={`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ${
              topPadded ? '-mt-32' : null
            }`}
          >
            The Urban Fox App for EV drivers
            <br />
          </h2>
          <p className="mt-4 text-xl text-gray-500 max-w-3xl">
            Find your nearest chargepoint, power your EV and track your usage.
          </p>
          <p className="mt-2 text-xl text-gray-500 max-w-3xl">
            Download our app now.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a href={links.appStore} target="_blank" rel="noreferrer">
              <img
                src="/images/common/app-store-badge.svg"
                alt="App Store"
                className="h-14 cursor-pointer"
              />
            </a>
            <a href={links.googlePlay} target="_blank" rel="noreferrer">
              <img
                src="/images/common/google-play-badge.png"
                alt="Play Store"
                className="h-20 cursor-pointer"
              />
            </a>
          </div>
        </div>
        <div className="hidden lg:flex flex-col items-start justify-start gap-y-6 w-full max-w-lg relative -mt-32">
          <img
            src="/images/common/app-promo.png"
            alt="Phone"
            className="absolute right-40 -top-12"
          />
        </div>
      </div>
    </div>
  )
}
