import React from 'react'

export default function ContactCTA() {
  return (
    <div class="bg-ufblack">
      <div class="mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div class="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
          <div>
            <h2 class="text-2xl font-bold text-uforange-600 sm:text-3xl sm:tracking-tight">
              Feedback
            </h2>
            <div class="mt-3">
              <p class="text-lg text-white">
                We are always looking for ways to improve our services. Send us
                an email to provide your feedback.
              </p>
            </div>
            <div class="mt-9">
              <div class="mt-6 flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-base text-white">
                  <a href="mailto:feedback@urbanfox.network">
                    feedback@urbanfox.network
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 md:mt-0">
            <h2 class="text-2xl font-bold text-uforange-600 sm:text-3xl sm:tracking-tight">
              Customer Support
            </h2>
            <div class="mt-3">
              <p class="text-lg text-white">
                Still can’t find the answer to your query? We have a 24/7
                helpdesk to provide charging support for our network users
              </p>
            </div>
            <div class="mt-9">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-base text-white">
                  <a href="tel:0808 175 3965">0808 175 3965</a>
                  <p class="mt-1">24/7 support line</p>
                </div>
              </div>
              <div class="mt-6 flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-base text-white">
                  <a href="mailto:support@urbanfox.network">
                    support@urbanfox.network
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
